.workoutheader-container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 25px;
}

.workoutheader-left{
    display: flex;
    flex-direction: row;
}

.workoutheader-right{
    display: flex;
    flex-direction: row;
    max-width: 400px;
}

.workoutheader-divider{
    border: 1px solid black;
    height: 30px;
    margin-top: 7%;
}

@media only screen and (max-width: 600px){
    .workoutheader-container{
        display: flex;
        flex-direction: column;
        margin-top: 25px;
    }
    .workoutheader-left{
        display: flex;
        flex-direction: row;
    }
    
    .workoutheader-right{
        display: flex;
        flex-direction: row;
        max-width: 400px;
    }
    
    .workoutheader-divider{
        border: 1px solid black;
        height: 30px;
        margin-top: 7%;
    }

    .workoutheader-right-name{
        margin-top: 40px;
        background-color: rgb(120, 0, 1, 0.1);
    }
}