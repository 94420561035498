body {
  margin: 0;
}

a {
  color: #ffffff;
}

.header-container {
  width: 100%;
  background-color: #1e1e1e;
  border-top: 3px solid rgba(249,88,54,1);
}

.global-header {
  border-width: 10px 0 0;
  max-width: 1280px;
  min-width: 800px;
  margin: auto;
}

.header-logo {
  max-width: 400px;
}

.header-logout-container{
  display: flex;
}

.header-login-name{
  margin-right: 20px;
  text-transform: uppercase;
}

.header-login-button{
  display: -webkit-inline-box;
display: -webkit-inline-flex;
display: -ms-inline-flexbox;
display: inline-flex;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-pack: center;
-ms-flex-pack: center;
-webkit-justify-content: center;
justify-content: center;
position: relative;
box-sizing: border-box;
-webkit-tap-highlight-color: transparent;
background-color: transparent;
outline: 0;
border: 0;
margin: 0;
border-radius: 0;
padding: 0;
cursor: pointer;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
vertical-align: middle;
-moz-appearance: none;
-webkit-appearance: none;
-webkit-text-decoration: none;
text-decoration: none;
color: inherit;
font-family: "Roboto","Helvetica","Arial",sans-serif;
font-weight: 500;
font-size: 0.875rem;
line-height: 1.75;
letter-spacing: 0.02857em;
text-transform: uppercase;
min-width: 64px;
padding: 5px 15px;
border-radius: 4px;
-webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
border: 1px solid rgba(249, 88, 54, 0.5);
color: rgba(249,88,54,1);
}

.header-login-button:hover{
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(249, 88, 54, 0.04);
  border: 1px solid rgba(249,88,54,1);
}

.header-login-button:active{
  background-color: rgba(249,88,54,1);
}