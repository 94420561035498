.workoutheadermobile-container{
    display: flex;
}

.workoutheadermobile-left{
    width: 50%;
    height: 120px;
    padding-left: 7%;
    height: 100%;
    margin: auto;
}

.workoutheadermobile-right{
    width: 50%;
    height: 120px;
    display: flex;
}

.workoutheadermobile-date-left{
    width: 50%;
}

.workoutheadermobile-date-right{
    width: 50%;
    margin-right: 5%;
}

.workoutheadermobile-date-left-card{
    background-color: #D9D9D9;
    border: 1px solid transparent;
    border-radius: 5px;
    height: 80px;
    width: 60%;
    margin: auto;
}

.workoutheadermobile-date-right-card{
    background-color: #D9D9D9;
    border: 1px solid transparent;
    border-radius: 5px;
    height: 80px;
    width: 60%;
    margin: auto;
}

