.login-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.login-header{
    margin-top: 2%;
}

.login-logo{
    width: 150px;
    height: auto;
}

:root, [data-amplify-theme] {
    --amplify-colors-font-primary: #fff;
    --amplify-colors-font-secondary: rgba(249,88,54,1);
    --amplify-components-button-primary-background-color: rgba(249,88,54,0.5);
    --amplify-components-fieldcontrol-color: #fff;
    --amplify-components-authenticator-router-background-color: transparent;
    --amplify-components-fieldcontrol-border-color: #7a7a7a;
    --amplify-components-button-active-background-color: #fff;
    --amplify-components-button-primary-disabled-color: #fff;
}

[data-amplify-authenticator] [data-amplify-router] {
    background-color: var(--amplify-components-authenticator-router-background-color);
    border-color: rgba(249,88,54,1);
}

.amplify-button--link{
    color: rgba(249,88,54,1);
}

.amplify-textfield{
    --amplify-components-fieldcontrol-focus-border-color : #fff;
}

.amplify-button--primary:hover {
    background-color: rgba(249,88,54,0.8);
    color: #fff;
    border: 1px solid transparent;
}

.amplify-button--primary:focus {
    background-color: rgba(249,88,54,0.8);
    color: #fff;
    border: 1px solid transparent;
}

.amplify-button--primary:active {
    background-color: rgba(249,88,54,1);
    color: #fff;
    border: 1px solid transparent;
}