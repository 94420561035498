.component-story-img{
    max-width: 50px;
}

.component-story-img:active{
    transform: scale(90%);
}

.component-story-p{
    color: #ffffff;
    opacity: 40%;
    font-size: 10px;
}

.story-border {
    border: 3px solid #000; 
    border-radius: 100%;
    background-color: white;
  }

  .story-border:active {
    transform: scale(95%);
  }

  .story-icon-embeded{
    width: 100%;
    height: 100%;
}

.story-modal-close{
    position: absolute;
    right: -10px;
    top: -10px;
}
