.error-container{
    display: flex;
    justify-content: center;
}

.error-left {
    display: flex;
    justify-content: flex-end;
    width: 30%;
}

.error-right {
    width: 50%;
    display: flex;
    justify-content: flex-start;
}

.error-right-container{
    max-width: 400px;
    display: flex;
    align-items: center;
}

.error-img {
    max-width: 200px; 
    height: auto;
}