.mobile-workout-info{
    color: rgba(249,88,54,1)
}

.mobile-workout-title{
    color: rgba(255,255,255,0.5);
    font-size: 12px;
}

.workoutlistmobile-card{
    padding: 5%;
    padding-bottom: 1%;
}

.workoutlistmobile-footer{
    height: 25px;
}

.hr-mobile {
    position: relative;
    outline: 0;
    border: 0;
    text-align: center;
    height: 1.6em;

  }
  .hr-mobile:before {
    content: "";
    background: linear-gradient(90deg, rgba(43,43,43,1) 0%, rgba(249,88,54,1) 50%, rgba(43,43,43,1) 100%);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 3px;
  }
  .hr-mobile:after {
    content: attr(data-symbol);
    position: relative;
    display: inline-block;
    color: black;
    padding: 0 0.5em;
    line-height: 1.5em;
    color: white;
    background-color: #1E1E1E;
    border: 1px solid rgba(249,88,54,1);
    font-family: 'Roboto';
  }