@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.mobile-container{
    height: 100%;
    background-color: #2a2a2a;
}

.mobile-header{
    border: 1px solid transparent;
}

.mobile-header-logo{
    padding: 10%;
    padding-bottom: 7%;
    padding-top: 7%;
}

.mobile-header-image{
    width: 100%;
}

.mobile-header-stories{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 7%;
    padding-top: 2%;
}


.mobile-content{
    border: 1px solid transparent;
}