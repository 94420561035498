
.new-container{
    background-color: white;
    padding: 3%;
    min-height: 80vh;
}

.new-center-container{
    padding: 15px;
}

.new-container-center{
    border: 1px solid rgba(0,0,0,0.3);
}

.new-athlete-info{
    padding: 35px;
    padding-top: 10px;
}

.new-athlete-container{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}

.new-save-data{
    padding-left: 40px;
}