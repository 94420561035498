.landing-container{
    display: flex;
    justify-content:space-between;
    flex-wrap: wrap;
    padding: 5%;
    margin: auto;
}

.landing-card{
    width: 250px;
    height: 270px;
    border-left: 2px solid rgba(249,88,54,1);
    background-color: #1e1e1e;
    display: flex;
    flex-direction: column;
    opacity: 70%;
    margin: 2%;
}

.landing-card:hover{
    opacity: 100%;
    cursor: pointer;
}

.landing-card:active{
    transform: scale(95%);
}

.landing-card-top{
    width: 100%;
    height: 70%;
}

.landing-card-bottom{
    width: 100%;
    height: 30%;
    padding: 5px;
    text-transform: lowercase;
}

.landing-card-img{
    width: 110px;
    margin: auto;
    display: flex;
    padding-top: 5%;
}