.workoutinfo-container{
    padding: 30px;
}

.workoutinfo-left{
    display: flex;
    flex-direction: row;
}

.workoutinfo-right{
    display: flex;
    flex-direction: row;
}

.workout-divider{
    border: 1px solid black;
    height: 30px;
    margin-top: 7%;
}

@media only screen and (max-width: 600px){
    .workoutinfo-container{
        padding: 1px;
        margin-bottom: 25px;
    }
    
    .workoutinfo-left{
        display: flex;
        flex-direction: row;
    }
    
    .workoutinfo-right{
        display: flex;
        flex-direction: row;
    }
    
    .workout-divider{
        border: 1px solid black;
        height: 30px;
        margin-top: 7%;
    }
}