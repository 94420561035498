body {
    background-color: #2a2a2a;
}

h5{
    margin: 0;
    color: white;
    font-family: 'Teko';
    font-size: 32px;
}

h6 {
    margin: 0;
    margin-top: -10px;
    font-family: 'Teko';
    font-weight: lighter;
    font-size: 37px;
    color: white;
}

.destkop-container{
    max-width: 1920px;
    display: flex;
    flex-direction: column;
    background-color: #2a2a2a;
    margin: auto;
    width: 100%;
}

.desktop-header{
    height: 26vh;
    background-color: #2a2a2a;
}

.desktop-header-span {
    font-family: "Wingdings";
    color: white;
}

.desktop-header-title{
    width: 100%;
    margin-top: 2%;
}
.desktop-header-title-image{
    width: 50%;
    max-width: 800px;
    margin: auto;
    display: flex;
}

.desktop-header-img {
    width: 25vh;
    transform: translateX(180);
}

.desktop-content-container{
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: -4vh;
    margin-left: 5vh;
    margin-right: 5vh;
    margin-bottom: 20px;
    background-color: #1e1e1e;
    box-shadow: 5px 5px 10px rgb(0,0,0,0.5);
    border-top: 5px solid rgba(249,88,54,1);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.desktop-content-instructions{
    padding: 20px;
    padding-bottom: 0;
}

.desktop-content-workouts{
    padding: 10px;
    margin-top: -10px;
}